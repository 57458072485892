import React, { memo } from 'react';
import { Message } from '@myci/intl';
import { o } from 'ramda';
import { reducer as authentication } from '@myci/authentication';
import { withReducers } from 'redux-syringe';
import { useDispatch } from 'react-redux';
import { Grid, Heading, SafeSuspense, Section, SkeletorForm } from '@myci/ui-components';
import { addToast } from '@ci/toasts';
import { useQueryParamEffect } from '@myci/navigation';
import { SignInForm } from '@myci/module-authentication';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const signInFormSkeletor = <SkeletorForm addFooter rowCount={2} />;

const SignIn = () => {
	const dispatch = useDispatch();

	useQueryParamEffect(
		'success',
		successParam => {
			if (successParam === 'true') {
				dispatch(
					addToast({
						type: 'success',
						content: m.authorizationSuccess,
						shouldAutomaticallyClose: false,
					})
				);
			} else if (successParam === 'false') {
				dispatch(
					addToast({
						type: 'error',
						content: m.authorizationFail,
						shouldAutomaticallyClose: false,
					})
				);
			}
		},
		[]
	);

	return (
		<PublicLayout
			headerClassName="section--sign-in"
			header={
				<Section pb={{ xs: 4, lg: 0 }} pt={4}>
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }}>
								<Heading as="h1" mb={{ xs: 3, md: 4 }}>
									<Message {...m.signInLink} />
								</Heading>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			<SafeSuspense fallback={signInFormSkeletor}>
				<SignInForm />
			</SafeSuspense>
		</PublicLayout>
	);
};

export default o(withReducers({ authentication }, { isGlobal: true }), memo)(SignIn);
