import React, { useCallback, useMemo } from 'react';
import { Grid } from '@myci/ui-components';
import { Form, useForm, useWatch, v } from '@ci/forms';
import { Message } from '@myci/intl';
import { isNilOrEmpty, isNilOrEmptyString } from 'ramda-extension';
import { changePassword, selectIsChangingPassword } from '@myci/authentication';
import { useDispatch, useSelector } from 'react-redux';
import {
	PasswordInputField,
	passwordMessages,
	usePasswordRules,
	validatePassword,
} from '@myci/password';
import { prepareStyle } from '@ci/styles';
import { Button } from '@ci/atoms';
import { Buttons } from '@ci/layout';
import { navigate } from '@gatsbyjs/reach-router';

import m from '../messages';
import { forms } from '../constants';

const buttonStyle = prepareStyle(utils => ({
	marginInlineStart: { md: utils.spacings.md },
	marginTop: { xs: utils.spacings.md, md: 0 },
}));

interface ChangePasswordFormValues {
	confirmPassword: string;
	password: string;
}

export interface ChangePasswordFormProps {
	token: string;
}

export const ChangePasswordForm = ({ token }: ChangePasswordFormProps) => {
	const isSubmitting = useSelector(selectIsChangingPassword);
	const dispatch = useDispatch();
	const { passwordRuleValidations } = usePasswordRules();

	const validate = useMemo(
		() =>
			v.combine(
				v.validate<ChangePasswordFormValues>(async values => {
					const clientSideFieldErrors = v.validate({
						password: [v.required(), ...passwordRuleValidations],
					})(values);

					if (clientSideFieldErrors) {
						return {
							confirmPassword: v.createFieldError({
								message: passwordMessages.passwordInvalid,
							}),
						};
					}
					const serverSideFieldErrors = await dispatch(
						validatePassword({ password: values.password ?? '' })
					);
					if (!isNilOrEmpty(serverSideFieldErrors)) {
						return {
							changedPassword: v.createFieldError(
								serverSideFieldErrors.map(error => ({
									message: error.message,
									options: { asyncError: true, code: error.code },
								}))
							),
						};
					}
					return null;
				}),
				v.validate<ChangePasswordFormValues>({
					password: [v.required()],
					confirmPassword: [v.required()],
				}),
				v.validate<ChangePasswordFormValues>(values => {
					if (
						values.password &&
						values.confirmPassword &&
						values.password !== values.confirmPassword
					) {
						return {
							confirmPassword: v.createFieldError({
								message: m.doesPasswordMatch,
							}),
						};
					}
					return null;
				})
			),
		[passwordRuleValidations]
	);
	const handleSubmit = useCallback(
		formValues => {
			dispatch(changePassword({ ...formValues, token }));
		},
		[dispatch]
	);

	const form = useForm({
		id: forms.changePassword,
		onSubmit: handleSubmit,
		mode: 'onBlur',
		defaultValues: {
			password: '',
		},
		validate,
	});

	const password = useWatch({ control: form.control, name: 'password' });

	return (
		<Form form={form}>
			<Grid container flexDirection="column" justifyContent="between">
				<Grid row>
					<Grid col={{ xs: 12, md: 6, lg: 5 }}>
						<Grid row mb={3}>
							<Grid col={{ xs: 12, md: 9 }}>
								<PasswordInputField
									name="password"
									label={<Message {...m.passwordLabel} />}
									isDisabled={isSubmitting}
									isRequired
									options={{ deps: ['confirmPassword'] }}
									hasRulesPopover
								/>
							</Grid>
						</Grid>
						<Grid row mb={3}>
							<Grid col={{ xs: 12, md: 9 }}>
								<PasswordInputField
									name="confirmPassword"
									label={<Message {...m.confirmPasswordLabel} />}
									isDisabled={isSubmitting}
									isRequired
								/>
							</Grid>
						</Grid>

						<Buttons
							isCentered
							verticalMargin="lg"
							buttons={[
								<Button
									icon="cancel"
									onClick={() => navigate('/sign-in')}
									variant="secondary"
									isOutlined
								>
									<Message {...m.cancelButton} />
								</Button>,
								<Button
									variant="primary"
									isDisabled={isNilOrEmptyString(password) || isSubmitting}
									icon="success"
									customStyle={buttonStyle}
									type="submit"
								>
									<Message {...m.changeButton} />
								</Button>,
							]}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Form>
	);
};
