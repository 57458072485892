import React, { FC, useCallback } from 'react';
import { Box, Grid } from '@myci/ui-components';
import { Link as UntypedGatsbyIntlLink } from '@myci/gatsby-plugin-intl';
import { Button } from '@ci/atoms';
import { Form, PasswordInputField, useForm, useFormState, v } from '@ci/forms';
import { IntlProvider, Message } from '@myci/intl';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import { equals } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsSigningIn, signIn } from '@myci/authentication';
import { InstanceTypes, currentInstanceType } from '@myci/utils';
import { Buttons } from '@ci/layout';
import { useInstanceConfig } from '@myci/instances';

import m from '../messages';
import { forms } from '../constants';
import { UserNameField } from './UserNameField';

const GatsbyIntlLink = UntypedGatsbyIntlLink as any;

interface SignInFormValues {
	password: string;
	username: string;
}
export const validateSignInForm = v.validate<SignInFormValues>({
	password: [v.required()],
	username: [v.required()],
});

export const SignInForm: FC<RouteComponentProps> = () => {
	const isSubmitting = useSelector(selectIsSigningIn);
	const dispatch = useDispatch();
	const { isRegistrationDisabled } = useInstanceConfig();

	const handleSignInClick = useCallback(
		values => {
			dispatch(signIn({ ...values }));
		},
		[dispatch]
	);

	const form = useForm({
		id: forms.signIn,
		mode: 'onChange',
		onSubmit: handleSignInClick,
		validate: validateSignInForm,
	});

	const formState = useFormState({ control: form.control });
	const { isDirty, isValid } = formState;

	const isSubmitDisabled = !isDirty || !isValid || isSubmitting;
	return (
		<IntlProvider>
			<Form form={form}>
				<Grid container flexDirection="column" justifyContent="between">
					<Grid row>
						<Grid col={{ xs: 12, md: 6, lg: 5 }}>
							<UserNameField name="username" isDisabled={isSubmitting} />
							<PasswordInputField
								name="password"
								label={<Message {...m.signInFieldPassword} />}
								isDisabled={isSubmitting}
								isRequired
							/>
							<Buttons
								verticalMargin="md"
								buttons={[
									<Button variant="primary" type="submit" isDisabled={isSubmitDisabled}>
										<Message {...m.logInSubmit} />
									</Button>,
								]}
							/>
							<Box className="mb-1">
								<GatsbyIntlLink to="/forgotten-password">
									<Message {...m.signInForgotPassword} />
								</GatsbyIntlLink>
							</Box>
							{equals(InstanceTypes.INSTANCE_FO, currentInstanceType) && !isRegistrationDisabled && (
								<Box>
									<GatsbyIntlLink to="/registration">
										<Message {...m.signInRegister} />
									</GatsbyIntlLink>
								</Box>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Form>
		</IntlProvider>
	);
};
